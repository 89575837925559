import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import NavBarComponent from '../components/NavBar/NavBarComponent'
import DashboardPage from '../pages/Home'
import WalletPage from '../pages/Wallet'
import PlanPage from '../pages/Plans'
import Deposti from '../pages/Deposit'
import useUser from '../hooks/useUser'
import MemberCreate from '../pages/Member/MemberCreate'
import Member from '../pages/Member'
import AdsFinish from '../pages/Ads/adsFinish'
import AdsFinishOne from '../pages/Ads/adsFinishOne'
import Withdraw from '../pages/Withdraw'
import usePurchase from '../hooks/usePurchase'
import ChangePassword from '../pages/ChangePassword'
import Transfer from '../pages/Transfer'
import Convert from '../pages/Convert'
import TokenWithdraw from '../pages/TokenWithdraw';

function AdminLayout() {
  const { checkLogin, fetchUserProfile, state: userState } = useUser()
  const { expirePurchase } = usePurchase()
  const [mounted, setMounted] = useState(false)
  const router = useLocation()

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      checkLogin()
    }
  }, [mounted, checkLogin])

  useEffect(() => {
    async function fetchData() {
      await fetchUserProfile()
    }
    fetchData()
  }, [router.pathname])

  useEffect(() => {
    const { purchase } = userState.user

    if (purchase) {
      if (purchase.plan?.duration !== 0) {
        let createdAt = new Date(purchase.createdAt)
        let duration = purchase.plan.duration

        let result = new Date(createdAt.setDate(createdAt.getDate() + duration))
        let current = new Date()

        if (current > result) {
          expirePurchase(purchase.id)
        }
      }
    }
  }, [userState.user, expirePurchase])

  return (
    <div>
      <header>
        <NavBarComponent />
      </header>
      <main className="admin-layout-container">
        <Routes>
          <Route path="/" element={<DashboardPage />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/plans" element={<PlanPage />} />
          <Route path="/deposit" element={<Deposti />} />
          <Route path="/member" element={<Member />} />
          <Route path="/create-member" element={<MemberCreate />} />
          <Route path="/ads-finish-step1" element={<AdsFinishOne />} />
          <Route path="/ads-finish" element={<AdsFinish />} />
          <Route path="/withdraw" element={<TokenWithdraw />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/transfer" element={<Transfer />} />
          <Route path="/convert" element={<Convert />} />
        </Routes>
      </main>
    </div>
  )
}

export default AdminLayout
