import React, { useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Card,
  CardBody,
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
} from 'reactstrap'
import useMember from '../../hooks/useMember'
import useUser from '../../hooks/useUser'
import MemberList from './list'
import { TreeView } from './treeView'

function Member() {
  const navigate = useNavigate()
  const [mounted, setMounted] = useState(false)
  const [activeTab, setActiveTab] = useState('1')
  const { state, fetchMember } = useMember()
  const { state: userState } = useUser()

  const tree = useMemo(() => {
    const result = JSON.parse(userState.user.tree.tree)

    if (result.code === userState.user.code) return result
    else {
      const codes = []
      for (var i = 1; i < userState.user.code.length; i += 2) {
        codes.push(userState.user.code.substr(0, i + 1))
      }

      let tempNode = null
      for (var node = 1; node < codes.length; node++) {
        let currentNode = node
        if (tempNode)
          tempNode = tempNode?.children?.find(
            (r) => r.code === codes[currentNode]
          )
        else
          tempNode = result?.children?.find(
            (r) => r.code === codes[currentNode]
          )
      }

      return tempNode
    }
  }, [userState.user])

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchMember()
    }
  }, [mounted, fetchMember])

  const { purchase } = userState.user

  if (!purchase) {
    return (
      <div className="py-5 d-flex justify-content-center">
        <h4 style={{ color: 'red' }}>You have no active plan</h4>
      </div>
    )
  }

  return (
    <Container>
      <Card
        style={{
          padding: '15px',
          borderRadius: '10px',
          background:
            'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
        }}
      >
        <CardHeader>
          <div
            style={{
              color: '#fff',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <h4>Members</h4>
            <Button
              className="button"
              color="success"
              onClick={() => navigate('/create-member')}
            >
              Add Member
            </Button>
          </div>
        </CardHeader>
        <CardBody style={{ borderRadius: '0px' }}>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames(
                  { 'active-tab': activeTab === '1' },
                  'tab'
                )}
                onClick={() => {
                  setActiveTab('1')
                }}
              >
                List
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  { 'active-tab': activeTab === '2' },
                  'tab'
                )}
                onClick={() => {
                  setActiveTab('2')
                }}
              >
                Tree
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <MemberList data={state.members} />
            </TabPane>
            <TabPane tabId="2">
              <TreeView data={tree} />
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Container>
  )
}

export default Member
