import React from 'react'
import { getAll } from './../service/paymentMethodservice'

const initialState = { paymentMethods: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_PAYMENT_METHODS':
      return { ...state, paymentMethods: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const usePaymentMethod = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchPaymentMethod = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_PAYMENT_METHODS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchPaymentMethod,
    state: state,
  }
}

export default usePaymentMethod
