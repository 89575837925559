import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll() {
  try {
    let response = await getData('transfer')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getTransferMemberAll() {
  try {
    let response = await getData('transfer/members')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function store(data) {
  try {
    let response = await sendData('transfer/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
