import { getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function each() {
  try {
    let response = await getData(`member-token-prices`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}