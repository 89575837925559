import { getData, sendData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAds() {
  try {
    let response = await getData('ads/get')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function getAdsOne(token) {
  try {
    let response = await sendData('ads/ads-step2/', { token: token })
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function finishAdsView(values) {
  try {
    let response = await sendData('ads/finish-ads', values)
    return response
  } catch (e) {
    NotificationManager.error('Invalid Request')
    return false
  }
}

export async function validateAdsFinishCheck(values) {
  try {
    let response = await sendData(
      'ads/validate-finish-ads',
      values,
      'POST',
      false
    )
    return response
  } catch (e) {
    return false
  }
}
