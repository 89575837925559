import React from 'react'
import { InputGroupText, InputGroup } from 'reactstrap'
import './text-box.scss'
function TextBox({
  icon,
  prepend = false,
  errors,
  customErrorMessage = '',
  registerProps,
  onChange,
  ...props
}) {
  return (
    <>
      <InputGroup
        className="custom-tb input-group-merge input-group-alternative border-0"
        addontype="prepend"
        style={{
          position: 'relative',
        }}
      >
        {props.type === 'textarea' ? (
          <textarea
            className="form-control "
            {...props}
            {...registerProps}
            autoComplete={'off'}
            style={{ minHeight: 100 }}
          ></textarea>
        ) : (
          <input
            className="form-control"
            {...props}
            {...registerProps}
            autoComplete={'off'}
            onChange={onChange}
            style={{
              background: 'rgba(15, 16, 40, 0.4)',
              border: '2px solid #3E4AC9',
              color: 'white',
            }}
          />
        )}
        {prepend && (
          <i
            style={{
              position: 'absolute',
              top: '20%',
              right: '4%',
              cursor: 'pointer',
              color: 'lightgray',
            }}
          >
            {icon}
          </i>
        )}
      </InputGroup>
      {errors && (
        <div
          className="error-containe"
          style={{ marginTop: props.type === 'textarea' ? 80 : 5 }}
        >
          <span className="text-danger">{errors?.message}</span>
        </div>
      )}
      {errors && errors.type === 'validate' && (
        <div className="error-container">
          <span className="text-danger">{customErrorMessage}</span>
        </div>
      )}
    </>
  )
}
export default TextBox
