import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  Row,
} from 'reactstrap'
import TextBox from '../../components/Inputs/TextBox'
import SelectBox from '../../components/SelectBox'
import useTokenPrice from '../../hooks/useTokenPrice'
import { getUserProfile } from '../../service/authService'
import {
  storeTokenToWallet,
  storeWalletToToken,
} from '../../service/convertService'

const bgColor =
  'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)'

function Convert() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
    getValues,
  } = useForm()
  const [modal, setModal] = useState(false)
  const [convertType, setConvertType] = useState(null)
  const [actionLoading, setActionLoading] = useState(false)

  const { state, getTokenPrice } = useTokenPrice()

  useEffect(() => {
    setValue('price', state.tokenPrice?.price)
  }, [setValue, state.tokenPrice])

  useEffect(() => {
    getTokenPrice()
  }, [])

  const options = ['Wallet To Token', 'Token To Wallet'].map((el) => {
    return {
      value: el,
      label: el,
    }
  })

  const submitData = async (values) => {
    const user = await getUserProfile()
    if (convertType === 'Wallet To Token') {
      if (user.wallet < values.convertion_value) {
        NotificationManager.warning("You don't have enough wallet amount!")
      } else {
        const data = {
          fromAmount: values.convertion_value,
          toAmount: values.amount,
        }
        await storeWalletToToken(data)
        window.location = '/convert'
      }
    } else if (convertType === 'Token To Wallet') {
      if (user.myToken < values.convertion_value) {
        NotificationManager.warning("You don't have enough token amount!")
      } else {
        const data = {
          fromAmount: values.convertion_value,
          toAmount: values.amount,
        }
        await storeTokenToWallet(data)
        window.location = '/convert'
      }
    }
  }

  return (
    <>
      <Container>
        <Form>
          <Card
            style={{
              border: '3px solid #2A2B57',
              marginTop: '8px',
              borderRadius: '15px',
              padding: '10px',
              background: bgColor,
            }}
          >
            <CardHeader>
              <h4 className="text-white">Make Convert</h4>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <label className="mb-2 text-white">Convert Type</label>
                <SelectBox
                  control={control}
                  className="form-control"
                  name="convertType"
                  rules={{ required: true }}
                  label="Convert Type"
                  options={options}
                  onValueChange={(value) => {
                    setConvertType(value)
                    let convertionValue = getValues('convertion_value')
                    if (value === 'Wallet To Token') {
                      setValue(
                        'amount',
                        convertionValue * state.tokenPrice?.price
                      )
                    } else if (value === 'Token To Wallet') {
                      setValue(
                        'amount',
                        convertionValue / state.tokenPrice?.price
                      )
                    } else {
                      setValue('amount', '')
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label className="mb-2 text-white">
                  1 USDT = {state.tokenPrice?.price} Token
                </label>
                <TextBox
                  type="number"
                  registerProps={register('price', {
                    required: 'Price is required',
                  })}
                  errors={errors.price}
                  disabled={true}
                />
              </FormGroup>
              <FormGroup>
                <label className="mb-2 text-white">Convertion Value</label>
                <TextBox
                  type="number"
                  registerProps={register('convertion_value', {
                    required: 'Convertion Value is required',
                  })}
                  errors={errors.convertion_value}
                  onChange={(event) => {
                    let value = event.target.value
                    if (convertType === 'Wallet To Token') {
                      setValue('amount', value * state.tokenPrice?.price)
                    } else if (convertType === 'Token To Wallet') {
                      setValue('amount', value / state.tokenPrice?.price)
                    } else {
                      setValue('amount', '')
                    }
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label className="mb-2 text-white">Amount</label>
                <TextBox
                  type="number"
                  registerProps={register('amount', {
                    required: 'Amount is required',
                  })}
                  errors={errors.amount}
                  disabled={true}
                />
              </FormGroup>
            </CardBody>
            <CardFooter className="py-4 d-flex justify-content-center">
              <Button
                size="lg"
                color="success"
                onClick={() => setModal(true)}
                style={{
                  border: '2px solid #67C9FF',
                  borderRadius: '6px',
                  background: 'rgba(15, 16, 40, 0.4)',
                }}
              >
                Preview
              </Button>
            </CardFooter>
          </Card>
        </Form>
      </Container>
      <Modal
        className="modal-dialog-centered"
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <ModalBody
          style={{
            background: bgColor,
            padding: '0px',
            margin: '0px',
          }}
        >
          <Card
            style={{
              background: bgColor,
            }}
          >
            <CardHeader>
              <h3>Preview Convertion</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>Convert Type </Col>
                <Col>{convertType}</Col>
              </Row>
              <Row className="mt-3">
                <Col>1 USDT </Col>
                <Col>{state.tokenPrice?.price} Token</Col>
              </Row>
              <Row className="mt-3">
                <Col>Convertion Value </Col>
                <Col>{getValues('convertion_value')}</Col>
              </Row>
              <Row className="mt-3">
                <Col>Amount </Col>
                <Col>{getValues('amount')}</Col>
              </Row>
            </CardBody>
            <CardFooter style={{ textAlign: 'right' }}>
              <Button
                size="lg"
                color="success"
                onClick={handleSubmit(submitData)}
                className="button"
              >
                Save
              </Button>
            </CardFooter>
          </Card>
        </ModalBody>
      </Modal>
    </>
  )
}

export default Convert
