import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { NotificationContainer } from 'react-notifications'
import AdminLayout from './layouts/AdminLayout'
import AuthLayout from './layouts/AuthLayout'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <Router>
      <main>
        <Routes>
          <Route path="*" element={<AdminLayout />} />
          <Route path="/auth/login" element={<AuthLayout />} />
        </Routes>
      </main>
      <NotificationContainer />
    </Router>
  )
}

export default App
