import React, { useEffect } from 'react'
import TextBox from '../../components/Inputs/TextBox'
import Logo from './../../assets/logo.png'
import { Col, Row, Button, Form } from 'reactstrap'
import SelectBox from '../../components/SelectBox'
import { useForm, useWatch } from 'react-hook-form'

function ExchangeInput() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    defaultValues: {
        coin:"BTC"
    }
  })

  const submitData = async (values) => {
    window.location="/withdraw";
  }

  const options = [
    {
      value: 'BTC',
      label: (
        <div
          style={{
            display: 'flex',
            columnGap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'black',
          }}
        >
          <span>BTC</span>
          <img
            src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
            style={{ width: 20, height: 20, marginRight: 10 }}
          />
        </div>
      ),
      price: 0.00003,
    },
    {
      value: 'ETH',
      label: (
        <div
          style={{
            display: 'flex',
            columnGap: '10px',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'black',
          }}
        >
          <span>ETH</span>
          <img
            src={
              'https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png'
            }
            style={{ width: 20, height: 20, marginRight: 10 }}
          />
        </div>
      ),
      price: 0.0006,
    },
  ]

  
  const watchCoin=useWatch({name:"coin",control});

  useEffect(()=>{
    if (watchCoin){
        setValue("token","");
        setValue("result","");
    }
  },[setValue, watchCoin])

  

  return (
    <Form onSubmit={handleSubmit(submitData)}>
      <div style={{ padding: '40px 20px' }}>
        <div>
          <TextBox
            className="form-control"
            placeholder="Enter your LP Token"
            type="number"
            registerProps={register('token', {
              required: 'lpToken is required',
            })}
            onChange={(e)=>{
                const selected=options.find((opt)=>opt.value===watchCoin);
          
                const result=parseFloat(e.target.value)*selected.price;
                
                if (!result){
                    setValue("result","");
                    return;
                }
                setValue("result",result.toFixed(5));
            }}
            errors={errors.lpToken}
            prepend
            icon={
              <img
                src={Logo}
                style={{ width: 30, height: 30, marginRight: 10 }}
              />
            }
          />
        </div>
        <div style={{ paddingTop: 20 }}>
          <Row>
            <Col sm="3">
              <SelectBox
                control={control}
                className="form-control"
                name="coin"
                rules={{ required: true }}
                label="Coin"
                options={options}
              />
            </Col>
            <Col sm="9">
              <TextBox
                className="form-control"
                readOnly={true}
                registerProps={register('result',{
                    required:"Required"
                })}
              />
            </Col>
          </Row>
        </div>
        <div style={{ padding: '20px 10px' }}>
          <Button>Exchange</Button>
        </div>
      </div>
    </Form>
  )
}

export default ExchangeInput
