import { getData, sendData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function store(data) {
  try {
    let response = await sendData('purchase/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function expire(id) {
  try {
    let response = await getData(`purchase/${id}`)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
