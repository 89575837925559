import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { NotificationManager } from 'react-notifications'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import TextBox from '../../components/Inputs/TextBox'
import SelectBox from '../../components/SelectBox'
import useTransfer from '../../hooks/useTransfer'
import useTransferAllowance from '../../hooks/useTransferAllowance'
import { getUserProfile } from '../../service/authService'

function Transfer() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm()
  const [mounted, setMounted] = useState(false)
  const [amount, setAmount] = useState(null)
  const [actionLoading, setActionLoading] = useState(false)

  const { state, fetchTransferMembers, storeTransfer } = useTransfer()

  const {
    state: transferAllowanceState,
    fetchTransferAllowance,
  } = useTransferAllowance()

  const transferAllowances = transferAllowanceState.transferAllowances.map(
    (el) => {
      return {
        value: el.amount,
        label: el.amount,
      }
    }
  )

  const options = state.transferMembers.map((el) => {
    return {
      value: el.id,
      label: `${el.name}(${el.code})`,
    }
  })

  useEffect(() => {
    if (!mounted) {
      setMounted(true)
      fetchTransferAllowance()
      fetchTransferMembers()
    }
  }, [mounted, fetchTransferMembers, fetchTransferAllowance])

  const submitData = async (values) => {
    const user = await getUserProfile()
    if (user.wallet < values.total) {
      NotificationManager.warning("You don't have enough amount!")
    } else {
      setActionLoading(true)
      await storeTransfer(values)
      window.location = '/wallet'
      reset()
      setAmount(null)
      setActionLoading(false)
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card
          style={{
            border: '3px solid #2A2B57',
            marginTop: '8px',
            borderRadius: '15px',
            padding: '10px',
            background:
              'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
          }}
        >
          <CardHeader>
            <h4 className="text-white">Make Transfer</h4>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <label className="mb-2 text-white">To Member</label>
              <SelectBox
                control={control}
                className="form-control"
                name="toMemberId"
                rules={{ required: true }}
                label="Amount"
                options={options}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Amount</label>
              <SelectBox
                control={control}
                className="form-control"
                name="amount"
                rules={{ required: true }}
                label="Amount"
                options={transferAllowances}
                value={amount}
                onValueChange={(value) => {
                  setAmount(value)
                  let transferFee = value * 0.03
                  setValue('transferFee', transferFee)
                  let total = value + transferFee
                  setValue('total', total)
                }}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Transfer Fee</label>
              <TextBox
                type="number"
                registerProps={register('transferFee', {
                  required: 'Transfer Fee is required',
                })}
                errors={errors.transferFee}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Total</label>
              <TextBox
                type="number"
                registerProps={register('total', {
                  required: 'Total is required',
                })}
                errors={errors.total}
                disabled={true}
              />
            </FormGroup>
            <FormGroup>
              <label className="mb-2 text-white">Note</label>
              <TextBox
                type="text"
                registerProps={register('note', {
                  required: 'Note is required',
                })}
                errors={errors.note}
              />
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-end">
            <Button
              size="lg"
              color="success"
              disabled={actionLoading}
              className="button"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default Transfer
