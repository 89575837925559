import React from 'react'
import LoginPage from '../pages/Login'

function AuthLayout() {
  return (
    <main className="d-flex">
      <LoginPage />
    </main>
  )
}

export default AuthLayout
