import React, { useEffect, useState } from 'react'
import { getAdsOne } from './../../service/adsService'
import { useSearchParams } from 'react-router-dom'
import { Button, Card, CardBody, Container } from 'reactstrap'
import { FaYahoo } from 'react-icons/fa'
import useAdsView from '../../hooks/useAdsView'
function AdsFinishOne() {
  const [params] = useSearchParams()

  if (!params.has('token')) {
    window.location = '/'
  }

  const [valid, setValid] = useState(false)
  const [adsLoading, setAdsLoading] = useState(false)
  const { finishView, validateAdsFinish, state: adsViewState } = useAdsView()

  useEffect(() => {
    async function fetchData() {
      const response = await validateAdsFinish({ token: params.get('token') })
      if (response.data.success) setValid(true)
    }
    fetchData()
  }, [])

  if (adsViewState.loading)
    return (
      <Container className="d-flex justify-content-center align-items-center py-5 mt-5">
        <h1 className="text-white">Loading...</h1>
      </Container>
    )

  if (!valid)
    return (
      <Container className="d-flex justify-content-center align-items-center py-5 mt-5">
        <h1 className="text-danger">Invalid Request</h1>
      </Container>
    )

  if (valid)
    return (
      <Container
        style={{ height: '80vh' }}
        className="d-flex justify-content-center align-items-center"
      >
        <Card style={{ width: '70%' }} className="py-5">
          <CardBody className="py-3" style={{ textAlign: 'center' }}>
            <FaYahoo
              style={{
                fontSize: 100,
                background: 'green',
                padding: '20px',
                color: 'white',
                borderRadius: '100%',
                marginBottom: 20,
              }}
            />
            <h1 className="text-success">Thank U !!</h1>
            <br />
            <Button
              disabled={adsViewState.actionLoading}
              onClick={async () => {
                setAdsLoading(true)
                const response = await getAdsOne(params.get('token'))
                if (response.status === 'success') {
                  window.location = response.shortenedUrl
                }
                setAdsLoading(false)
              }}
            >
              Get The Link
            </Button>
          </CardBody>
        </Card>
      </Container>
    )
}

export default AdsFinishOne
