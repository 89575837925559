import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'
import { storeCache } from './../utils/cache'
import { removeCache } from './../utils/cache'

export async function login(values) {
  try {
    let response = await sendData('app-login', values)
    storeCache('access_token', response.data.token)
    return response
  } catch (e) {
    NotificationManager.error('Login Failed')
    return false
  }
}

export async function getUserProfile() {
  let response = await getData('app/profile')
  storeCache('user', JSON.stringify(response.data))
  return response.data
}

export function logout() {
  removeCache('user')
  removeCache('access_token')
}

export async function getHomePageData() {
  let response = await getData('app/homepage')
  return response.data
}
