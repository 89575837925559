import React from 'react'
import { finishAdsView, validateAdsFinishCheck } from '../service/adsService'

const initialState = { loading: false, actionLoading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    case 'SET_ACTION_LOADING':
      return { ...state, actionLoading: action.payload }
    default:
      throw new Error()
  }
}

const useAdsView = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const finishView = async (values) => {
    dispatch({ type: 'SET_ACTION_LOADING', payload: true })
    if (await finishAdsView(values)) return true
    return false
    dispatch({ type: 'SET_ACTION_LOADING', payload: false })
  }

  const validateAdsFinish = async (values) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let response = await validateAdsFinishCheck(values)
    dispatch({ type: 'SET_LOADING', payload: false })
    return response
  }

  return {
    finishView,
    validateAdsFinish,
    state,
  }
}

export default useAdsView
