import { store, expire } from '../service/purchaseService'

const usePurchase = () => {
  const storePurchase = async (values) => {
    await store(values)
  }

  const expirePurchase = async (id) => {
    await expire(id)
  }

  return {
    storePurchase,
    expirePurchase,
  }
}

export default usePurchase
