import React from 'react'
import { getAll, getTransferMemberAll, store } from '../service/transferService'

const initialState = { transfers: [], transferMembers: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_TRANSFER':
      return { ...state, transfers: action.payload.data }
    case 'SET_TRANSFER_MEMBERS':
      return { ...state, transferMembers: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useTransfer = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchTransfer = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_TRANSFER', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const fetchTransferMembers = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getTransferMemberAll()
    dispatch({ type: 'SET_TRANSFER_MEMBERS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeTransfer = async (values) => {
    await store(values)
  }

  return {
    fetchTransfer,
    fetchTransferMembers,
    storeTransfer,
    state: state,
  }
}

export default useTransfer
