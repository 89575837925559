import React from 'react'
import { getAll, each, store } from '../service/withdrawService'

const initialState = { withdraws: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_WITHDRAW':
      return { ...state, withdraws: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useWithdraw = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchWithdraw = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_WITHDRAW', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }
  const getWithdraw = async (id) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await each(Number(id))

    dispatch({ type: 'SET_WITHDRAW', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  const storeWithdraw = async (values) => {
    await store(values)
  }

 
  

  return {
    getWithdraw,
    fetchWithdraw,
    storeWithdraw,
    state: state,
  }
}

export default useWithdraw
