import { sendData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function storeWalletToToken(data) {
  try {
    let response = await sendData('convert/walletToToken/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function storeTokenToWallet(data) {
  try {
    let response = await sendData('convert/tokenToWallet/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
