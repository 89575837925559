import { sendData, getData } from './apiService'
import { NotificationManager } from 'react-notifications'

export async function getAll() {
  try {
    let response = await getData('member-to-member')
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function store(data) {
  try {
    let response = await sendData('member-to-member/store', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function changePassword(data) {
  try {
    let response = await sendData('member-to-member/change-password', data)
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}

export async function resetPassword(id) {
  try {
    let response = await getData('member-to-member/reset-password/' + id)
    NotificationManager.success('Successfully reset password!');
    return response.data
  } catch (e) {
    NotificationManager.error('Opps. Something wrong')
    return false
  }
}
