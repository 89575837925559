import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Card, CardBody, Container } from 'reactstrap'
import { FaYahoo } from 'react-icons/fa'
import useAdsView from '../../hooks/useAdsView'
import useUser from '../../hooks/useUser'
function AdsFinish() {
  const [params] = useSearchParams()

  if (!params.has('token')) {
    window.location = '/'
  }

  const [valid, setValid] = useState(false)

  const { finishView, validateAdsFinish, state: adsViewState } = useAdsView()

  const { state: userState } = useUser()

  useEffect(() => {
    async function fetchData() {
      const response = await validateAdsFinish({ token: params.get('token') })
      if (response.data.success) setValid(true)
    }
    fetchData()
  }, [])

  if (adsViewState.loading)
    return (
      <Container className="d-flex justify-content-center align-items-center py-5 mt-5">
        <h1 className="text-white">Loading...</h1>
      </Container>
    )

  if (!valid)
    return (
      <Container className="d-flex justify-content-center align-items-center py-5 mt-5">
        <h1 className="text-danger">Invalid Request</h1>
      </Container>
    )

  if (true)
    return (
      <Container
        style={{ height: '80vh' }}
        className="d-flex justify-content-center align-items-center"
      >
        <Card style={{ width: '70%' }} className="py-5">
          <CardBody className="py-3" style={{ textAlign: 'center' }}>
            <FaYahoo
              style={{
                fontSize: 100,
                background: 'green',
                padding: '20px',
                color: 'white',
                borderRadius: '100%',
                marginBottom: 20,
              }}
            />
            <h1 className="text-success">Thank U !!</h1>
            <h3 className="text-success">You have watched ads</h3>
            <br />
            <Button
              disabled={adsViewState.actionLoading}
              onClick={async () => {
                if (
                  userState.user.directMembers >=
                  userState.user.purchase.plan.referCount
                ) {
                  if (await finishView({ token: params.get('token') })) {
                    window.location = '/'
                  }
                } else {
                  window.location = '/'
                }
              }}
            >
              Save Your Activity
            </Button>
          </CardBody>
        </Card>
      </Container>
    )
}

export default AdsFinish
