import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormGroup,
} from 'reactstrap'
import TextBox from '../../components/Inputs/TextBox'
import { FaRegEyeSlash } from 'react-icons/fa'
import useMember from '../../hooks/useMember'

function ChangePassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const [showPassword, setShowPassword] = useState(false)

  const { changePasswordMember } = useMember()

  const submitData = async (values) => {
    await changePasswordMember(values)
    reset()
  }

  const styles = {
    color: '#838383',
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card
          style={{
            border: '3px solid #2A2B57',
            marginTop: '8px',
            borderRadius: '15px',
            padding: '10px',
            background:
              'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
          }}
        >
          <CardHeader>
            <h4 className="text-white">Change Password</h4>
          </CardHeader>
          <CardBody>
            <FormGroup>
              <label className="mb-2 text-white">Password</label>
              <TextBox
                placeholder="Min.8 characters"
                type={showPassword ? 'text' : 'password'}
                registerProps={register('password', {
                  required: 'Password is required',
                })}
                errors={errors.password}
                prepend
                icon={
                  <FaRegEyeSlash
                    style={styles}
                    onClick={() => {
                      setShowPassword(!showPassword)
                    }}
                  />
                }
              />
            </FormGroup>
          </CardBody>
          <CardFooter className="py-4 d-flex justify-content-end">
            <Button size="lg" color="success" className="button">
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}

export default ChangePassword
