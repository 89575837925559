import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardFooter,
  Container,
} from 'reactstrap'
import { FaAudioDescription, FaMendeley } from 'react-icons/fa'
import { useCallback, useEffect, useState } from 'react'
import { getAds } from './../../service/adsService'
import { useNavigate } from 'react-router-dom'
import useUser from './../../hooks/useUser'
import WalletIcon from './../../assets/wallet.png'
import './index.scss'
import Logo from './../../assets/logo.png'
import useBlog from '../../hooks/useBlog'
import ProjectPlanImage from './../../assets/project_plan.PNG'
import TextBox from '../../components/Inputs/TextBox'
import ExchangeInput from './ExchangeInput'

const TitleCard = ({ title }) => {
  return (
    <div
      style={{
        border: '1px solid #3D4AC9',
        borderRadius: '3px',
        paddingTop: '8px',
        marginBottom: '8px',
        width: '280px',
        background:
          'linear-gradient(90deg, rgba(62, 74, 201, 0.2) 0%, rgba(175, 70, 182, 0.2) 34.17%, rgba(93, 200, 213, 0.2) 69.06%, rgba(62, 74, 201, 0.2) 100%)',
      }}
    >
      <h5
        style={{
          background: 'rgba(71, 180, 214, 0.2)',
          padding: '3px 20px',
          textAlign: 'center',
        }}
      >
        {title}
      </h5>
    </div>
  )
}

export default function Dashboard() {
  const { state: userState, getHomePageData } = useUser()
  const navigate = useNavigate()
  const [adsLoading, setAdsLoading] = useState(false)

  const { state: blogState, fetchBlog } = useBlog()

  const viewAds = useCallback(async () => {
    setAdsLoading(true)
    const response = await getAds()
    if (response.status === 'success') {
      window.location = response.shortenedUrl
    }
    setAdsLoading(false)
  }, [navigate])

  useEffect(() => {
    async function fetchData() {
      await getHomePageData()
    }
    fetchData()
  }, [])

  useEffect(() => {
    fetchBlog()
  }, [])

  const { purchase } = userState.user

  if (userState.loading)
    return (
      <div className="p-4">
        <h1 className="text-white">Loading...</h1>
      </div>
    )

  return (
    <div
      style={{
        color: '#fff',
      }}
    >
      <h5
        style={{
          textAlign: 'center',
        }}
      >
        Current Amount
      </h5>
      <div
        style={{
          background: 'rgba(62, 74, 201, 0.2)',
          textAlign: 'center',
          padding: '30px',
          margin: '30px 0px',
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
        }}
      >
        {/* <h1>{userState?.user?.wallet} USDT</h1> */}
        <TitleCard title={`My Token - ${userState?.user?.myToken}`} />
        <TitleCard
          title={`Wallet - ${userState?.user?.wallet.toLocaleString()} USDT`}
        />
        <TitleCard title={`Hold Token - ${userState?.user?.holdToken}`} />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <h5
          style={{
            background: 'rgba(62, 74, 201, 0.2)',
            padding: '20px 50px',
            borderLeft: '2px solid #3E4AC9',
            borderRight: '2px solid #3E4AC9',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Total Member: {userState?.user?.directMembers}
        </h5>
        <h5
          style={{
            background: 'rgba(62, 74, 201, 0.2)',
            padding: '20px 50px',
            borderTop: '2px solid #3E4AC9',
          }}
        >
          Current Plan:{' '}
          <img
            src={userState?.user?.purchase?.plan?.logo}
            alt="Plan"
            width="70px"
            height="70px"
            style={{
              borderRadius: '10px',
            }}
          />
        </h5>
      </div>
      <div
        style={{
          margin: '100px 5% 0px 5%',
          background: 'rgba(15, 16, 40, 0.8)',
          border: '1px solid #67C9FF',
          borderRadius: '8px',
          padding: '10px',
        }}
      >
        <img src={ProjectPlanImage} style={{ width: '100%' }} />
      </div>
      <div
        style={{
          background: 'rgba(62, 74, 201, 0.2)',
          margin: '40px 5%',
          textAlign: 'center',
          borderLeft: '2px solid #3E4AC9',
          borderRight: '2px solid #3E4AC9',
          padding: '30px',
        }}
      >
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th colSpan={5}>
                <h5>Exchange</h5>
              </th>
            </tr>
            <tr>
              <td colSpan={5}>
               <ExchangeInput />
              </td>
            </tr>
            <tr style={{ background: '#26224d', height: 50 }}>
              <th>Coin</th>
              <th>Price</th>
              <th>To</th>
              <th>Coin</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: 40 }}>
              <td>
                <img
                  src={Logo}
                  style={{ width: 25, height: 25, marginRight: 10 }}
                />
                LP Token
              </td>
              <td>1 LP</td>
              <td>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2546/2546705.png"
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
              </td>
              <td>
                <img
                  src="https://cryptologos.cc/logos/bitcoin-btc-logo.png"
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
                BTC
              </td>
              <td>0.00003 BTC</td>
            </tr>
            <tr style={{ height: 40 }}>
              <td>
                <img
                  src={Logo}
                  style={{ width: 25, height: 25, marginRight: 10 }}
                />
                LP Token
              </td>
              <td>1 LP</td>
              <td>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/2546/2546705.png"
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
              </td>
              <td>
                <img
                  src={
                    'https://w7.pngwing.com/pngs/268/1013/png-transparent-ethereum-eth-hd-logo-thumbnail.png'
                  }
                  style={{ width: 20, height: 20, marginRight: 10 }}
                />
                ETH
              </td>
              <td>0.0006 ETH</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ height: 100 }}></div>
    </div>
  )
}
