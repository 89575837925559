import React from 'react'
import { getAll } from './../service/blogService'

const initialState = { blogs: [], loading: false }

function reducer(state, action) {
  switch (action.type) {
    case 'SET_BLOGS':
      return { ...state, blogs: action.payload.data }
    case 'SET_LOADING':
      return { ...state, loading: action.payload }
    default:
      throw new Error()
  }
}

const useBlog = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const fetchBlog = async () => {
    dispatch({ type: 'SET_LOADING', payload: true })
    let data = await getAll()
    dispatch({ type: 'SET_BLOGS', payload: { data: data } })
    dispatch({ type: 'SET_LOADING', payload: false })
  }

  return {
    fetchBlog,
    state: state,
  }
}

export default useBlog
